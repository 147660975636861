<template>
  <div class="mainform">
    <div class="mainHeader">
      直发送货需求
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form">
      <el-form class="content" ref="form" :class="{disabled:formDisabled}" :disabled="formDisabled" :model="form" >
        <div class="elrowflx">
          <el-row :gutter="20">
            <el-col class="clearfix" :span="12">
              <div class="xjtit">送货需求单号</div>
              <div class="xjcont">{{demandCode}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">需求下发时间</div>
              <div class="xjcont">{{xqXfTime}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">送货地址</div>
              <div class="xjcont">{{dataBox.baseName}}</div>
            </el-col>
            <el-col v-if="useName" class="clearfix" :span="12">
              <div class="xjtit">发布人</div>
              <div class="xjcont">{{userName}}</div>
            </el-col>
            <el-col v-if="buyName" class="clearfix" :span="12">
              <div class="xjtit">采购员</div>
              <div class="xjcont">{{buyerName}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">联系人</div>
              <div class="xjcont">{{dataBox.baseHead}} {{dataBox.phone}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">状态</div>
              <div class="xjcont">{{dataBox.status === 1 ? '待采购员确认' : dataBox.status === 2 ? '待确认' : dataBox.status === 3 ? '已确认' : dataBox.status === 4 ? '已签收' : ''}}</div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">期望送达日期</div>
              <div class="xjcont">
                <el-form-item label-width="0">
                  <el-date-picker v-model="qwSdTime" type="date" placeholder="请选择"></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
            <el-col class="clearfix" :span="12">
              <div class="xjtit">备注</div>
              <div class="xjcont">{{dataBox.remark}}</div>
            </el-col>
          </el-row>
        </div>
        <div class="excel-tab">
          <el-table size=mini :data="form.bidScoringList" stripe style="width: 100%">
            <el-table-column prop="demandCode" label="物料编号" :show-overflow-tooltip="true" width="120px"></el-table-column>
            <el-table-column prop="materialName" label="物料描述" :show-overflow-tooltip="true" width="160px"></el-table-column>
            <el-table-column prop="supplierMaterialCode" label="我的物料编号" :show-overflow-tooltip="true" width="120px"></el-table-column>
            <el-table-column prop="supplierMaterialName" label="我的物料描述" :show-overflow-tooltip="true" width="160px"></el-table-column>
            <el-table-column prop="orderCode" label="采购单编号" :show-overflow-tooltip="true" width="120px"></el-table-column>
            <el-table-column prop="orderRow" label="采购单行号" :show-overflow-tooltip="true" width="90px"></el-table-column>
            <el-table-column prop="xsdBh" label="销售单编号" :show-overflow-tooltip="true" width="120px"></el-table-column>
            <el-table-column prop="demandNumber" label="需求数量" :show-overflow-tooltip="true" width="90px"></el-table-column>
            <el-table-column prop="dw" label="单位" :show-overflow-tooltip="true" width="70px"></el-table-column>

            <el-table-column v-if="form.sn !== 0" prop="fileInfoList" label="SN" :show-overflow-tooltip="true" width="120px">
              <template #default="scope">
                <div class="suppUp">
                  <el-upload
                    ref="upload"
                    name="file"
                    :auto-upload="true"
                    :action= 'postUrl'
                    :on-exceed="handleExceed"
                    :limit="1"
                    :on-remove="handleRemove(scope.row)"
                    :on-success="handSuccess(scope.row)"
                    :on-error="handError"
                    :headers="tokenHeader"
                    :file-list="upDownFlie"
                  >
                    <span class="spanUp">+上传</span>
                  </el-upload>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="entrepotName" label="出仓库位" width="120px" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-select
                v-model="scope.row.entrepotInfoEntity"
                filterable
                remote
                reserve-keyword
                value-key="id"
                placeholder="库位"
                @change="changeValue(scope.row)"
                @focus="clearData(scope.row)"
                :remote-method="querySearch">
                  <el-option
                    v-for='item in newSelect'
                    :key='item.id'
                    :label='item.entrPosiName'
                    :value='item'>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </el-form>
      <div class="qsdClass" v-if="dataBox.status === 4">
        <span>签收单</span>
        <el-button type="text" @click="disDownFlie">{{form.bidScoringList[0].signFileName}}</el-button>
      </div>
      <div class="action">
        <div v-if="dataBox.status === 2">
          <el-button class="submit" type="primary" size="medium" @click="confirm">确认</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="edist">编辑</el-button>
        </div>
        <div v-if="dataBox.status === 3">
          <el-button v-if="edisType === 2" class="submit" type="primary" size="medium" @click="confirm">确认</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="edist">编辑</el-button>
          <el-button v-if="edisType === 1" class="submit" type="primary" size="medium" @click="upData">上传签收单</el-button>
        </div>
      </div>
      <!-- 上传签收单弹框 -->
      <div class="dialogBox">
        <el-dialog top="0px" title="上传签收单" v-model="dialogModify" width="40%">
          <div class="content">
            <div class="mainform">
              <div class="form">
                <el-form :model="endTimeData" ref="endTimeData" label-width="110px" class="demo-ruleForm">
                  <el-form-item label="签收日期" prop="endTime">
                    <el-date-picker type="datetime" @change="timeForm" placeholder="选择日期" v-model="endTimeData.endTime" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="签收单" prop="sn">
                    <el-upload
                      ref="upload"
                      name="file"
                      :auto-upload="true"
                      :action= 'postUrl'
                      :on-preview="downBylob"
                      :on-exceed="handleExceed"
                      :on-remove="handleRemoveQsd"
                      :limit="1"
                      :on-success="handSuccessReceipt"
                      :on-error="handError"
                      :headers="tokenHeader"
                      :file-list="upReturnData"
                    >
                      <el-button size="mini" type="primary" plain>+上传</el-button>
                    </el-upload>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="dialog-footer">
              <el-button size="mini" @click="dialogModify = false">取 消</el-button>
              <el-button size="mini" type="primary" @click="edisUpData">确 定</el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { baseURL, request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'HairSupplierDetails',
  data: function () {
    return {
      useName: false,
      buyName: true,
      userName: '',
      buyerName: '',
      xqXfTime: '',
      qwSdTime: '',
      edisType: 1,
      upReturnData: [],
      upDownFlie: [],
      newSelect: [],
      endTimeData: {
        endTime: ''
      },
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/file/m/file/uploadToServer',
      spanShow: true,
      demandCode: this.$route.query.demandCode,
      formDisabled: false,
      dialogShow: false,
      dialogModify: false,
      menudata: this.$store.state.ViewMenuData.buttonData,
      dataBox: {},
      form: {
        desc: '',
        sn: '',
        shTime: '',
        businessScope: '',
        bidScoringList: []
      }
    }
  },
  mounted () {
    if (this.demandCode) {
      this.formDisabled = true
    }
  },
  created () {
    this.getIdData()
  },
  methods: {
    // 根据需求单号请求数据
    getIdData () {
      const demandCode = this.demandCode
      if (demandCode) {
        request('/api/livraison/direct/getLivraisonDirectDetailDataListByDemandCode?' + 'demandCode=' + demandCode, 'POST').then((res) => {
          if (res.code === '200') {
            this.form.bidScoringList = res.data.directDemandData
            this.userName = this.form.bidScoringList[0].userName
            this.buyerName = this.form.bidScoringList[0].buyerName
            this.xqXfTime = this.form.bidScoringList[0].issueTime
            this.qwSdTime = this.form.bidScoringList[0].demandTime
            this.form.sn = this.form.bidScoringList[0].snFlag
            this.dataBox = res.data.baseData
            this.form.xjDescribe = res.data.ruleComment
            this.form.checkBusinessScope = res.data.ruleSupplier
            this.form.creationDate = res.data.creationDate
            this.form.createUsername = res.data.createUsername
            this.form.ruleType = res.data.ruleType
            this.form.proofType = res.data.proofType
            this.dataBox.status = this.form.bidScoringList[0].status
            this.dataBox.remark = this.form.bidScoringList[0].remark
            this.form.bidScoringList.forEach(a => {
              a.fileInfo = []
              if (!a.entrepotInfoEntity) {
                a.entrepotInfoEntity = []
              } else {
                this.newSelect = [a.entrepotInfoEntity]
              }
              if (a.fileInfoList !== null) {
                this.upDownFlie = a.fileInfoList
              }
            })
          }
        })
      }
    },

    // 表格里的选择库位输入后匹配内容
    querySearch (queryString) {
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          newListEnt.push({
            id: item.id,
            createUserId: item.createUserId,
            createDate: item.createDate,
            modifyDate: item.modifyDate,
            entrPosiCode: item.entrPosiCode,
            entrPosiName: item.entrPosiName,
            entrPosiType: item.entrPosiType,
            providerCode: item.providerCode,
            providerName: item.providerName,
            entrPosiAddr: item.entrPosiAddr,
            entrPosiHead: item.entrPosiHead,
            entrPosiHeadPhone: item.entrPosiHeadPhone,
            deleteFlag: item.deleteFlag
          })
        })
        this.newSelect = newListEnt
      })
    },
    // 判断库位输入库是否有值
    clearData (row) {
      if (row.entrepotInfoEntity.length !== 0) {
        this.newSelect = []
      }
    },
    // 选中库位给列表中库位赋值
    changeValue (row) {
      row.entrepotName = row.entrepotInfoEntity.lable
    },
    // 点击详情页编辑按钮
    edist () {
      this.edisType = 2
      this.useName = false
      this.buyName = true
      this.formDisabled = false
    },
    // 确认
    confirm () {
      var isShow = true
      this.form.bidScoringList.forEach(item => {
        if (this.form.sn !== 0) {
          if (!item.fileInfoList) {
            if (!item.entrepotInfoEntity || item.fileInfo.length === 0) {
              isShow = false
            }
          }
        } else {
          if (!item.entrepotInfoEntity) {
            isShow = false
          }
        }
      })
      if (this.form.sn !== 0) {
        if (!isShow) {
          this.$message({
            showClose: true,
            message: '请点击编辑，补充SN和出库仓位',
            type: 'warning'
          })
          return false
        }
      } else {
        if (!isShow) {
          this.$message({
            showClose: true,
            message: '请点击编辑，补充出库仓位',
            type: 'warning'
          })
          return false
        }
      }

      this.edisType = 1
      this.demandCode = this.$route.query.demandCode
      var demandDirectList = []
      var newQwSdTime = ''
      if (this.qwSdTime) {
        newQwSdTime = dayjs(this.qwSdTime).format('YYYY-MM-DD')
      }
      this.form.bidScoringList.forEach(item => {
        demandDirectList.push({
          demandCode: item.demandCode,
          demandRow: item.demandRow,
          demandCodeRow: item.demandCode + '-' + item.demandRow,
          entrepotInfoEntity: item.entrepotInfoEntity,
          fileInfo: item.fileInfo[0]
        })
      })
      const obj = {
        demandDirectList: demandDirectList,
        demandTime: newQwSdTime
      }
      request('/api/livraison/direct/updateLivraisonDirectDetailDataListByDemandCodeOfPrivate', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.useName = true
          this.buyName = false
          this.formDisabled = true
          this.getIdData()
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          })
        }
      })
    },
    // 上传签收单
    upData () {
      this.dialogModify = true
    },
    // 点击上传签收单里的确认按钮
    edisUpData () {
      if (!this.endTimeData.endTime) {
        this.$message({
          showClose: true,
          message: '请选择签收日期',
          type: 'warning'
        })
      } else if (this.upReturnData.length === 0) {
        this.$message({
          showClose: true,
          message: '请上传签收单',
          type: 'warning'
        })
      } else {
        const obj = {
          files: this.upReturnData,
          signTime: this.endTimeData.endTime,
          demandCode: this.demandCode
        }
        request('/api/livraison/direct/uploadFileOfSign', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.dialogModify = false
            this.getIdData()
            this.$message({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
          }
        })
      }
    },
    // 下载签收单
    downBylob (file) {
      fileHandle.handlePreview(file)
    },
    // 上传成功后下载签收单
    disDownFlie () {
      var newSignFilerID = this.form.bidScoringList[0].signFileId
      var newSignFilerName = this.form.bidScoringList[0].signFileName
      this.downBylob({ id: newSignFilerID, name: newSignFilerName })
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (file) {
      this.$message({
        showClose: true,
        message: '只能上传一个文件',
        type: 'warning'
      })
    },

    // 验证上传文件格式
    // handleChansge (file, fileList) {
    //   如需验证格式加上:on-change="handleChansge"
    //   if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
    //     this.$notify.error({
    //       title: '错误',
    //       message: '上传文件只能为excel文件，且为xlsx,xls格式'
    //     })
    //     return false
    //   }
    // },

    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        message: '只能上传一个文件',
        type: 'warning'
      })
    },
    // 上传成功或错误提示
    handSuccess (row) {
      return (response, file, fileList) => {
        if (response.code === '200') {
          response.data.forEach(item => {
            row.fileInfo.push(item)
          })
        }
      }
    },
    // 移除上传SN文件
    handleRemove (row) {
      return (file) => {
        for (var i = 0; i < row.fileInfo.length; i++) {
          if (row.fileInfo[i].name === file.name) {
            row.fileInfo.splice(i, 1)
          }
        }
      }
    },
    // 签收单上传成功或错误提示
    handSuccessReceipt (response, file, fileList) {
      if (response.code === '200') {
        // this.upReturnData = response.data[0]
        response.data.forEach(item => {
          this.upReturnData.push(item)
        })
      }
    },
    // 移除上传签收单文件
    handleRemoveQsd (file) {
      this.upReturnData = []
    },
    // 选择报价截止日期转换格式
    timeForm (val) {
      if (this.endTimeData.endTime) {
        this.endTimeData.endTime = dayjs(this.endTimeData.endTime).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.endTimeData.endTime = ''
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/dialogBox.scss';
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .excel-tab{
    margin-bottom: 20px;
    .spanUp{
      color: #409EFF;
    }
    :deep(.el-input__inner){
      height: 30px !important;
      line-height: 30px !important;
    }
  }
  .mg-form{
    margin-top: 20px;
    :deep(.el-table th){
      padding: 9px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      padding: 9px 0;
      font-size: 14px;
    }
    .elrowflx{
      margin-bottom: 20px;
      :deep(.el-col){
        // margin-bottom: 20px
        line-height: 34px;
      }
    }
    .xjtit{
      float: left;
      margin-right: 15px;
      min-width: 90px;
      text-align: right;
      color: #666;
      font-size: 14px;
    }
    .xjcont{
      float: left;
      width: 50%;
      color: #666;
      font-size: 14px;
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .disabled{
      :deep(.el-input__prefix){
        display: none;
      }
      :deep(.el-input__inner){
        padding: 0;
      }
    }
    .disabled{
      :deep(.el-textarea__inner) {
        background: none;
        border: none;
        font-size: 14px;
        padding: 7px 0;
        color: #666;
        font-family: "Microsoft YaHei";
      }
      .spanUp{
        display: none;
      }
      :deep(.el-input__inner){
        color: #666;
        font-size: 14px;
        background: none;
        border: 0;
      }
    }
    .suppUp{
      .el-tooltip{
        position: relative;
      }
      .el-upload-list{
        position: absolute;
        left: 0;
        top: 10px;
        background: #fff;
        height: 26px;
      }
      :deep(.el-upload-list__item){
        transition: none;
        margin-top: 0;
        border: none !important;
        height: 26px;
      }
      :deep(.el-upload-list__item:hover){
        border: none;
      }
      .el-icon-close-tip{
        display: none !important;
      }
      .foucusing{
        border: none !important;
      }
      :deep(.el-upload-list__item.is-success .el-upload-list__item-name:focus) {
          color: #666;
          cursor: context-menu;
      }
      :deep(.el-upload-list__item.is-success .el-upload-list__item-name:hover) {
          color: #666;
          cursor: context-menu;
      }
      :deep(.el-upload-list__item-name){
        display: inline-block;
        overflow: hidden;
        margin-right: 20px;
        width: 90px;
      }
    }
  }
  .qsdClass{
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .qsdClass span{
    padding-right: 15px;
  }
</style>
